function toggleMenu() {
  const navigation = document.getElementsByClassName('main-navigation');

  if (navigation[0].classList.contains('active')) {
    navigation[0].classList.remove('active');
    return;
  }

  navigation[0].classList.add('active');
}

document.addEventListener('DOMContentLoaded', () => {
  const menuOpener = document.querySelector('.menu-opener');
  const menuCloser = document.querySelector('.menu-closer');
  const menuEntry = document.querySelectorAll('.entry-link');

  menuEntry.forEach((item) => {
    item.addEventListener('click', toggleMenu, false);
  });

  menuOpener.addEventListener('click', toggleMenu, false);
  menuCloser.addEventListener('click', toggleMenu, false);
});

import Fullpage from 'fullpage.js/dist/fullpage.min';

const sectionHome = document.querySelector('.section-home');
const sectionInnovation = document.querySelector('.section-innovation');
const sectionPlastik = document.querySelector('.section-plastik-vermeiden');
const sectionBiosiegel = document.querySelector('.section-biosiegel');
const sectionMarkenbotschafterin = document.querySelector('.section-markenbotschafterin');
const sectionKarton = document.querySelector('.section-karton');
const sectionNaturebox = document.querySelector('.section-naturebox');
const sectionPlastik2 = document.querySelector('.plastik2');
const sectionFooter = document.querySelector('.section-footer');

function scrollIntoView(event, element) {
  if (element === 2) {
    sectionHome.scrollIntoView();
  } else if (element === 2) {
    sectionInnovation.scrollIntoView();
  } else if (element === 3) {
    sectionPlastik.scrollIntoView();
  } else if (element === 4) {
    sectionBiosiegel.scrollIntoView();
  } else if (element === 5) {
    sectionMarkenbotschafterin.scrollIntoView();
  } else if (element === 6) {
    sectionKarton.scrollIntoView();
  } else if (element === 7) {
    sectionNaturebox.scrollIntoView();
  } else if (element === 8) {
    sectionPlastik2.scrollIntoView();
  } else if (element === 9) {
    sectionFooter.scrollIntoView();
  }
}

function removeEventlistener() {
  document.getElementById('1')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('2')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('3')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('4')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('5')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('6')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('7')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('8')
    .removeEventListener('click', scrollIntoView);
  document.getElementById('9')
    .removeEventListener('click', scrollIntoView);
}

function addEventlistener() {
  if (window.innerWidth >= 1024) {
    return;
  }
  document.getElementById('1')
    .addEventListener('click', scrollIntoView.bind(null, event, 1), false);
  document.getElementById('2')
    .addEventListener('click', scrollIntoView.bind(null, event, 2), false);
  document.getElementById('3')
    .addEventListener('click', scrollIntoView.bind(null, event, 3), false);
  document.getElementById('4')
    .addEventListener('click', scrollIntoView.bind(null, event, 4), false);
  document.getElementById('5')
    .addEventListener('click', scrollIntoView.bind(null, event, 5), false);
  document.getElementById('6')
    .addEventListener('click', scrollIntoView.bind(null, event, 6), false);
  document.getElementById('7')
    .addEventListener('click', scrollIntoView.bind(null, event, 7), false);
  document.getElementById('8')
    .addEventListener('click', scrollIntoView.bind(null, event, 8), false);
  document.getElementById('9')
    .addEventListener('click', scrollIntoView.bind(null, event, 9), false);
}

function activateFullpage() {
  if (window.innerWidth >= 1024) {
    removeEventlistener();

    Fullpage('#fullpage', {
      licenseKey: '1BD0A9B2-DDD64A68-B03D8E38-2CCBD89D',
      navigation: true,
      anchors: ['Home', 'Innovationen', 'Vorteile', 'Umweltsiegel', 'Ursula-Karven', 'Produkte', 'Naturebox', 'Plastikmüll-vermeiden', 'Mitmachen', 'Rechtliches'],
      navigationTooltips: ['Start', 'Innovationen', 'Vorteile', 'Umweltsiegel', 'Ursula Karven', 'Produkte', 'Naturebox', 'Plastikmüll vermeiden', 'Mitmachen', 'Rechtliches'],
      css3: true,
      scrollingSpeed: 400,
      dragAndMove: true,
      controlArrows: false,
      afterResize(width, height) {
        if (width < 1024) {
          fullpage_api.destroy('all');
          addEventlistener();
        }
      },
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('resize', activateFullpage, false);
  activateFullpage();
  addEventlistener();
});

if (module.hot) {
  module.hot.accept();
}
